import { PropsWithChildren, useEffect, useState } from "react"
import { fromPairs } from "ramda"
import axios from "axios"
import { ASSETS } from "config/constants"
import createContext from "utils/createContext"
import { useCustomNetworks } from "data/settings/CustomNetworks"

export const [useNetworks, NetworksProvider] =
  createContext<CustomNetworks>("useNetworks")

const InitNetworks = ({ children }: PropsWithChildren<{}>) => {
  const [networks, setNetworks] = useState<CustomNetworks>()
  const { list } = useCustomNetworks()

  useEffect(() => {
    const fetchChains = async () => {
      // const { data: chains } = await axios.get<TerraNetworks>("/chains.json", {
      //   baseURL: ASSETS,
      // })

      const chains = {
        mainnet: {
          name: "mainnet",
          chainID: "phoenix-1",
          lcd: "https://terra-lcd.publicnode.com",
          api: "https://phoenix-api.terra.dev",
          hive: "https://phoenix-hive.terra.dev/graphql",
          walletconnectID: 1,
        },
        classic: {
          name: "classic",
          chainID: "columbus-5",
          lcd: "https://terra-classic-lcd.publicnode.com",
          api: "https://terra-classic-public-api.publicnode.com",
          mantle: "https://columbus-mantle.terra.dev",
          walletconnectID: 2,
        },
        testnet: {
          name: "testnet",
          chainID: "pisco-1",
          lcd: "https://pisco-lcd.terra.dev",
          api: "https://pisco-api.terra.dev",
          hive: "https://pisco-hive.terra.dev/graphql",
          walletconnectID: 0,
        },
        localterra: {
          name: "localterra",
          chainID: "localterra",
          lcd: "http://localhost:1317",
          mantle: "http://localhost:1337",
        },
      }

      const networks = {
        ...chains,
        localterra: { ...chains.localterra, preconfigure: true },
      }

      setNetworks({
        ...networks,
        ...fromPairs(list.map((item) => [item.name, item])),
      })
    }

    fetchChains()
  }, [list])

  if (!networks) return null
  return <NetworksProvider value={networks}>{children}</NetworksProvider>
}

export default InitNetworks
